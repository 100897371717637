/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */

/* 1. For Realytics, we will only track French page visits.
	The received code will be in French i.e. "boutique_chiens" for the dog collection and is decided by Realytics
	There are some exceptions to this, some codes are not in French, like "mail_newsletter"
  2. We will only track visits in production
*/
const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
const trackRealyticsVisit = (realyticsTrackingCode: string): void => {
  if ((window as any).RY && isProduction) {
    (window as any).RY.track(realyticsTrackingCode);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { trackRealyticsVisit };
