import { useEffect } from 'react';

import classNames from 'classnames';
import { gql } from 'graphql-request';
import { GetStaticPaths, GetStaticProps } from 'next';
import { useRouter } from 'next/router';

import { bffRequest } from 'api/client/bff-request';
import productFragment from 'api/client/fragments/collectionProduct';
import { Product } from 'api/generated-types';
import AsSeenOn from 'components/content/AsSeenOn';
import Benefits from 'components/content/Benefits';
import BestsellersV2 from 'components/content/BestsellersV2';
import Charity from 'components/content/Charity';
import CompareIngredients from 'components/content/CompareIngredients';
import Footnote3 from 'components/content/Footnote3';
import ProductQuizActivation3 from 'components/content/ProductQuizActivation3';
import RealIngredients from 'components/content/RealIngredients';
import Testimonials from 'components/content/Testimonials';
import Newsletter2 from 'components/parts/Newsletter2';
import TrustBox from 'components/parts/TrustBox';
import { Button, ECImage, ECLink, Screen, Section, SectionItem } from 'components/ui';
import { useCountryContext } from 'context/CountryContextProvider';
import { ImageSetKeys, getImageSets, useImages } from 'context/ImagesProvider';
import { useIntercom } from 'context/IntercomProvider';
import { useTracking } from 'context/TrackingProvider';
import Layout from 'layouts/Layout';
import { Testimonial } from 'services/contentful/generated-types';
import serverSideSeo from 'services/seo/serverSideSeo';
import serverSideTranslations from 'services/translations/serverSideTranslations';
import { useTranslations } from 'services/translations/TranslationsProvider';
import { getShippingCountryCodesDuringBuild } from 'utils/country/getShippingCountryCodes';
import { localesWithoutDefault } from 'utils/localeHelpers';
import { getPagePath } from 'utils/page-paths';
import createNextPage from 'utils/pages/createNextPage';
import { fetchTestimonialsByKey } from 'utils/testimonialUtils';

interface Props {
  bestsellersData: Product[];
  testimonials: Testimonial[];
}

export default createNextPage<Props>(
  ({ bestsellersData, testimonials }: Props) => {
    const { t } = useTranslations('page.home.2024');
    const { img } = useImages(ImageSetKeys.PageHome2024);
    const { showIntercom } = useIntercom();
    const { countryCode } = useCountryContext();
    const { locale } = useRouter();
    const {
      events: { trackCtaClicked },
    } = useTracking();

    let heroDesktop = 'heroDesktopLifestyle';
    let heroMobile = 'heroMobileLifestyle';

    if (countryCode === 'BE') {
      heroDesktop = 'heroDesktopLifestyleBE';
      heroMobile = 'heroMobileLifestyleBE';
    }

    useEffect(() => {
      if (window.location.hash.indexOf('intercom') > 0) {
        showIntercom();
      }
    });

    return (
      <div className="overflow-hidden">
        <h1 className="visually-hidden">{t('hiddenPageTitle')}</h1>
        <div
          className="bg-cyan-400 relative py-12 flex overflow-hidden items-center
          maxMd:pb-[280px]
          maxLg:pb-[400px]
          lg:min-h-[520px] lg:h-[27vw]"
        >
          <div className="container relative z-10">
            <div className="max-w-copy text-center mx-auto">
              <h2
                className={classNames(
                  'inline-block leading-none text-purple-800 font-rodetta mb-4',
                  'text-2.5xl sm:text-3xl lg:text-3.5xl',
                )}
              >
                {`${t('heroTitleLine1')} `}
                <br />
                {t('heroTitleLine2')}
              </h2>
              <div className="mb-8 text-purple-800 text-lg lg:text-xl font-rooney font-medium">
                <p>
                  {`${t('heroIntroLine1')} `}
                  <br />
                  {t('heroIntroLine2')}
                </p>
              </div>
              {/* hero buttons */}
              <div className="flex flex-wrap gap-4 justify-center">
                {/* shop cats */}
                <ECLink href={getPagePath({ path: '/collections/cats', locale })}>
                  <Button
                    color="purple-800"
                    size="large"
                    onClick={() => trackCtaClicked({ ctaId: 'hero_shop_cats' })}
                  >
                    {t('heroCtaCats')}
                  </Button>
                </ECLink>
                {/* shop dogs */}
                <ECLink href={getPagePath({ path: '/collections/dogs', locale })}>
                  <Button
                    color="purple-800"
                    size="large"
                    onClick={() => trackCtaClicked({ ctaId: 'hero_shop_dogs' })}
                  >
                    {t('heroCtaDogs')}
                  </Button>
                </ECLink>
              </div>
            </div>
          </div>

          <div className="maxLg:hidden block absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] w-[100%] h-[100%] lg:min-w-[1800px]">
            <ECImage
              img={img(heroDesktop)}
              srcOptions={{
                w: 3500,
                q: 90,
              }}
              className="absolute left-0 top-0 w-[100%] h-[100%] object-cover object-[50%,40%]"
            />
            {countryCode === 'BE' && (
              <ECImage
                img={img('testaankoop')}
                srcOptions={{
                  w: 500,
                  q: 90,
                }}
                className="absolute z-20 w-[5%] top-[45%] left-[72%] xl:top-[45%] xl:left-[74%]"
              />
            )}
          </div>

          <div className="lg:hidden block absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] w-[100%] md:w-[1100px] h-[100%] min-w-[720px]">
            <ECImage
              img={img(heroMobile)}
              srcOptions={{
                w: 1440,
                q: 90,
              }}
              overrideMobile
              className="absolute left-0 top-0 w-[100%] h-[100%] object-cover object-[50%,100%]"
            />
            {countryCode === 'BE' && (
              <ECImage
                img={img('testaankoop')}
                srcOptions={{
                  w: 400,
                  q: 90,
                }}
                className="absolute z-20 w-[12%] bottom-[4%] left-[60%]"
              />
            )}
          </div>
        </div>
        <Section color="white" size="small" className="maxLg:!py-6">
          <Screen>
            <TrustBox template="micro-combo" />
          </Screen>
          <Screen md>
            <TrustBox template="micro-star" />
          </Screen>
        </Section>
        <Section color="brown-50">
          <Benefits v2 />
        </Section>
        <RealIngredients />
        <Section color="brown-50">
          <BestsellersV2 bestsellers={bestsellersData} />
        </Section>
        {countryCode === 'BE' && <AsSeenOn />}
        <CompareIngredients />
        <ProductQuizActivation3 ctaId="home_product_quiz_landing" />
        <Section color="brown-200" style={{ backgroundColor: '#FCD28C' }}>
          <Testimonials testimonials={testimonials} v2 />
        </Section>
        <Section color="brown-50" noBottomSpacing>
          <SectionItem>
            <Charity v2 />
          </SectionItem>
          <SectionItem>
            <Newsletter2 v2 />
          </SectionItem>
        </Section>
        <Footnote3 listId="home" />
      </div>
    );
  },
  {
    Layout,
  },
);

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
  const countryCodes = await getShippingCountryCodesDuringBuild();

  return {
    paths: countryCodes.flatMap((countryCode) =>
      localesWithoutDefault(locales).map((locale) => ({
        params: {
          countryCodeWithPrefix: `country-${countryCode}`,
        },
        locale,
      })),
    ),
    fallback: 'blocking',
  };
};

const bestsellersQuery = gql`
  ${productFragment}
  query getBestsellers($universalKeys: [String!], $locale: String!, $countryCode: String!) {
    products(universalKeys: $universalKeys, locale: $locale, countryCode: $countryCode) {
      ...collectionProductFields
    }
  }
`;

export const getStaticProps: GetStaticProps<unknown, { countryCodeWithPrefix: string }> = async (
  ctx,
) => {
  const { locale, params } = ctx;
  if (locale === 'default') return { props: {} };
  const { countryCodeWithPrefix } = params;
  const countryCode = countryCodeWithPrefix.split('-')[1];

  const bestsellersUniversalKeys = [
    'BUNDLE_DOG_AD_MIX-BEST-1',
    'BUNDLE_DOG_AD_MIX-FEST-1',
    'DOG_AD_KIBBLE_SALMON40',
    'CAT_AD_KIBBLE_CHICKEN50',
    countryCode === 'IT'
      ? 'CAT_AD_PATE_DUCK10-FESTIVE'
      : 'BUNDLE_CAT_AD_CHUNKS-FESTIVE-3FLAV-6X85G',
    'CAT_AD_CHUNKS_CHICKEN46',
  ];

  const [resources, images, seo, testimonials] = await Promise.all([
    serverSideTranslations(
      locale,
      [
        'common',
        'products',
        'form',
        'alerts.newsletter',
        'page.home',
        'page.home.2024',
        'content.newsletter',
        'content.charityDonation',
        'content.footnote',
        'content.benefits',
        'content.bestsellers',
        'content.testimonials',
        'content.productQuizActivation',
        'content.charity',
        'content.footnote2',
        'content.realIngredients',
        'content.compareIngredients',
      ],
      ctx,
    ),
    getImageSets(
      {
        locale,
        keys: [
          ImageSetKeys.PageHome2024,
          ImageSetKeys.ContentProductQuizActivation,
          ImageSetKeys.ContentNewsletter,
          ImageSetKeys.ContentBenefits,
          ImageSetKeys.ContentBestsellers,
          ImageSetKeys.ContentCharity,
          ImageSetKeys.ContentRealIngredients,
          ImageSetKeys.ContentCompareIngredients,
          ImageSetKeys.ContentAsSeenOn,
        ],
      },
      ctx,
    ),
    serverSideSeo(locale, 'page.home', ctx),
    fetchTestimonialsByKey('testimonials.dogAndCat', locale),
  ]);

  const bestsellersData = await bffRequest<{ products: Product[] }>({
    query: bestsellersQuery,
    variables: {
      locale,
      countryCode,
      universalKeys: bestsellersUniversalKeys,
    },
    ctx,
  }).then((res) => {
    res.products?.sort(
      (a, b) =>
        bestsellersUniversalKeys.findIndex((p) => p === a.universalKey) -
        bestsellersUniversalKeys.findIndex((p) => p === b.universalKey),
    );

    return res.products;
  });

  return !resources
    ? { notFound: true }
    : {
        props: {
          resources,
          seo,
          images,
          countryCode,
          bestsellersData,
          testimonials,
        },
        revalidate: parseInt(process.env.NEXT_REVALIDATE_SECONDS, 10),
      };
};
