/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';

import { ECImage, Section } from 'components/ui';
import { ImageSetKeys, useImages } from 'context/ImagesProvider';

const AsSeenOn = (): JSX.Element => {
  const { img } = useImages(ImageSetKeys.ContentAsSeenOn);

  return (
    <Section color="white" size="small" className="!py-6">
      <div className="container container--md">
        <div className="flex gap-4 items-center justify-between">
          <ECImage
            img={img('play4')}
            pictureClassName="w-[100px] flex justify-center"
            className="h-[24px]"
            srcOptions={{
              h: 96,
              q: 90,
            }}
          />
          <ECImage
            img={img('netflix')}
            pictureClassName="w-[100px] flex justify-center"
            className="h-[24px]"
            srcOptions={{
              h: 96,
              q: 90,
            }}
          />
          <ECImage
            img={img('hln')}
            pictureClassName="w-[100px] flex justify-center"
            className="h-[24px]"
            srcOptions={{
              h: 96,
              q: 90,
            }}
          />
        </div>
      </div>
    </Section>
  );
};

export default AsSeenOn;
