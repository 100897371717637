/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';

import Link from 'next/link';

import { Button, ECImage, Icon, Section } from 'components/ui';
import { ImageSetKeys, useImages } from 'context/ImagesProvider';
import { useTracking } from 'context/TrackingProvider';
import { useTranslations } from 'services/translations/TranslationsProvider';

interface Props {
  ctaId?: string;
}

const ProductQuizActivation3 = ({ ctaId }: Props): JSX.Element => {
  const { img } = useImages(ImageSetKeys.ContentProductQuizActivation);
  const { t } = useTranslations('content.productQuizActivation');
  const {
    events: { trackCtaClicked },
  } = useTracking();

  const image = 'illustration';

  return (
    <Section color="brown-50">
      <div className="overflow-hidden">
        <div className="container mx-auto grid lg:grid-cols-2 grid-cols-1 items-center lg:gap-8">
          <div className="flex flex-col items-start">
            <div className="max-w-copy maxLg:mx-auto">
              <h3
                className="text-2.5xl sm:text-3xl leading-none font-rodetta text-balance
                mx-auto text-purple-800 mb-8 text-center lg:text-left w-full maxLg:flex maxLg:justify-center"
              >
                {t('title', {
                  meal: `${t('titleMeal')}`,
                })}
              </h3>
              <div className="lg:flex flex-col items-start hidden">
                <Link href="/quiz/" passHref legacyBehavior>
                  <Button
                    color="purple-800"
                    size="large"
                    onClick={() => ctaId && trackCtaClicked({ ctaId })}
                  >
                    {t('ctaLabel')}
                  </Button>
                </Link>
                <div className="text-purple-800 font-rooney text-sm flex justify-center mt-4">
                  <Icon name="clock" className="w-4" />
                  <p className="pl-1 font-medium">{t('durationIndication')}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="relative mx-auto maxLg:max-w-[550px] lg:w-[750px] 2xl:w-[900px]">
            <div
              className="bg-white rounded relative flex flex-col font-rooney text-purple-800 p-4 shadow w-[250px] mx-auto -mr-8 z-10
              lg:absolute lg:top-[6%] lg:left-[50%] lg:translate-x-[-50%] lg:ml-[-30%] lg:mr-0"
            >
              <div className="text-sm">{t('cardDogTitle')}</div>
              <div className="text-base font-rodetta" style={{ color: '#67A512' }}>
                {t('cardDogRecipe')}
              </div>
              <ul className="mt-2 flex flex-col text-sm font-medium">
                <li className="flex items-start gap-x-2">
                  <Icon
                    name="checkmark-3"
                    className="text-[#67A512] w-3 h-3 shrink-0 relative top-[2px]"
                  />
                  <span>{t('cardDogClaim1')}</span>
                </li>
                <li className="flex items-start gap-x-2">
                  <Icon
                    name="checkmark-3"
                    className="text-[#67A512] w-3 h-3 shrink-0 relative top-[2px]"
                  />
                  <span>{t('cardDogClaim2')}</span>
                </li>
              </ul>
            </div>
            <ECImage
              className="w-full pointer-events-none max-w-none maxLg:-ml-[25%] maxLg:-mt-[80px] maxLg:w-[140%]"
              img={img(image)}
              srcOptions={{ w: 1450, q: 95 }}
            />
          </div>

          <div className="flex flex-col items-center lg:hidden mt-8">
            <Link href="/quiz/" passHref legacyBehavior>
              <Button
                color="purple-800"
                size="large"
                onClick={() => ctaId && trackCtaClicked({ ctaId })}
              >
                {t('ctaLabel')}
              </Button>
            </Link>
            <div className="text-purple-800 font-rooney text-sm flex justify-center mt-4">
              <Icon name="clock-mini" className="w-4" />
              <p className="pl-2 font-medium">{t('durationIndication')}</p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default ProductQuizActivation3;
