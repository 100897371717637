import React from 'react';

import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ECImage, Icon } from 'components/ui';
import { ImageSetKeys, useImages } from 'context/ImagesProvider';
import { useTracking } from 'context/TrackingProvider';
import { useTranslations } from 'services/translations/TranslationsProvider';
import { DONATED_TO_CHARITY } from 'utils/constants';

interface Props {
  // New color
  v2?: boolean;
}

const Charity = ({ v2 }: Props): JSX.Element => {
  const { img } = useImages(ImageSetKeys.ContentCharity);
  const { t } = useTranslations('content.charity');
  const { locale } = useRouter();

  const {
    events: { trackCtaClicked },
  } = useTracking();

  return (
    <div>
      <div className="container">
        <h3
          className={classNames(
            'mx-auto text-center max-w-copy leading-none font-rodetta mb-10 text-balance',
            v2
              ? 'text-purple-800 text-2.5xl sm:text-3xl'
              : 'text-purple-700 text-3xl md:text-3.5xl',
          )}
        >
          {t('charityTitle')}
        </h3>
        {/* carousel of charity cards */}

        <Swiper
          slidesPerView={1.1}
          spaceBetween={16}
          style={{ marginBottom: -15, paddingBottom: 15, overflow: 'visible' }}
          breakpoints={{
            512: { slidesPerView: 1.7 },
            768: {
              slidesPerView: 2.2,
              spaceBetween: 32,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 32,
            },
          }}
        >
          <SwiperSlide
            style={{
              userSelect: 'none',
              height: 'auto',
            }}
          >
            <a
              href="https://edgardcooperfoundation.org/"
              target="_blank"
              onClick={() => trackCtaClicked({ ctaId: `ec_foundation_card` })}
            >
              <div className="flex flex-col rounded-2xl bg-white h-full shadow hover:shadow-lg transform transition hover:-translate-y-[0.125rem]">
                <div className="overflow-hidden">
                  <ECImage
                    img={img('charity.1')}
                    pictureClassName="min-w-full min-h-full"
                    className="min-w-full min-h-full object-cover rounded-t-2xl"
                  />
                </div>
                <div className="m-4 mb-[1.375rem] flex flex-col grow">
                  <h4
                    className={classNames(
                      'font-rooney text-lg font-bold mb-6 grow',
                      v2 ? 'text-purple-800' : 'text-purple-700',
                    )}
                  >
                    {t('charity.1.line1')}
                  </h4>
                  <p className="text-gray-500 font-rooney text-base leading-5">
                    {t('charity.1.line2')}
                  </p>
                  <div
                    className={classNames(
                      'absolute bottom-4 right-4 rounded-full p-2 w-8 h-8 flex items-center justify-center',
                      v2 ? 'bg-purple-800' : 'bg-purple-700',
                    )}
                  >
                    <Icon name="arrow" className="absolute w-6 h-6 text-white" />
                  </div>
                </div>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide
            style={{
              userSelect: 'none',
              height: 'auto',
            }}
          >
            <a
              href="https://edgardcooperfoundation.org/"
              target="_blank"
              onClick={() => trackCtaClicked({ ctaId: `ec_foundation_card` })}
            >
              <div className="bg-purple-550 h-full rounded-2xl text-white px-4 py-6 shadow hover:shadow-lg transform transition hover:-translate-y-[0.125rem]">
                <ECImage img={img('foundationLogo')} />
                <p className="font-rooney text-base font-medium mt-8">{t('charity.2.line1')}</p>
                <p className="font-rooney text-base font-medium">{t('charity.2.line2')}</p>
                <p className="mt-8">
                  <span className="text-3xl lg:text-4xl font-rodetta leading-[3rem]">
                    €{Number(DONATED_TO_CHARITY).toLocaleString(locale).toString()}
                  </span>
                  <br />
                  <span className="font-rooney text-lg leading-6 font-medium mt-2">
                    {t('charity.2.line3')}
                  </span>
                </p>
                <div className="absolute bottom-4 right-4 bg-white rounded-full p-2 w-8 h-8 flex items-center justify-center">
                  <Icon name="arrow" className="absolute w-6 h-6 text-purple-700" />
                </div>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide
            style={{
              userSelect: 'none',
              height: 'auto',
            }}
          >
            <a
              href="https://edgardcooperfoundation.org/"
              target="_blank"
              onClick={() => trackCtaClicked({ ctaId: `ec_foundation_card` })}
            >
              <div className="flex flex-col rounded-2xl bg-white h-full shadow hover:shadow-lg transform transition hover:-translate-y-[0.125rem]">
                <div className="overflow-hidden">
                  <ECImage
                    img={img('charity.3')}
                    pictureClassName="min-w-full min-h-full"
                    className="min-w-full min-h-full object-cover rounded-t-2xl"
                  />
                </div>
                <div className="m-4 mb-[1.375rem] flex flex-col grow">
                  <h4
                    className={classNames(
                      'font-rooney text-lg font-bold mb-6 grow',
                      v2 ? 'text-purple-800' : 'text-purple-700',
                    )}
                  >
                    {t('charity.3.line1')}
                  </h4>
                  <p className="text-gray-500 font-rooney text-base leading-5">
                    {t('charity.3.line2')}
                  </p>
                  <div
                    className={classNames(
                      'absolute bottom-4 right-4 rounded-full p-2 w-8 h-8 flex items-center justify-center',
                      v2 ? 'bg-purple-800' : 'bg-purple-700',
                    )}
                  >
                    <Icon name="arrow" className="absolute w-6 h-6 text-white" />
                  </div>
                </div>
              </div>
            </a>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Charity;
