import React, { useState } from 'react';

import { Icon, Section } from 'components/ui';
import { useTranslations } from 'services/translations/TranslationsProvider';

interface Props {
  listId: 'home';
}

const Footnote3 = ({ listId }: Props) => {
  const { t } = useTranslations('content.footnote2');
  const [showReferences, setShowReferences] = useState(false);

  if (!listId) {
    return <></>;
  }

  const listIds = {
    home: [
      {
        description: 'footnote.home.1.description',
        link: null,
      },
      { description: 'footnote.home.2.description', link: null },
    ],
  };

  const toggleReferences = () => {
    setShowReferences(!showReferences);
  };

  return (
    <Section color="white" size="small" id="references">
      <div className="container container--sm font-rooney text-gray-500 text-sm">
        <h3 className="font-bold text-gray-500 mb-4 text-xl leading-7">{t('footnote.title')}</h3>
        <p className="mb-4">{t('footnote.description')}</p>
        <button className="group flex items-center" type="button" onClick={toggleReferences}>
          <h4 className="font-bold text-gray-700 text-sm leading-tight group-hover:text-purple-750 transition">
            {showReferences ? t('footnote.hideReferences') : t(`footnote.showReferences`)}
          </h4>
          <Icon
            name="chevron-down-narrow"
            className={`text-purple-800 w-4 h-4 ml-1 group-hover:text-purple-750 transition transform ${
              showReferences ? 'rotate-180' : ''
            }`}
          />
        </button>
        <ul
          className="flex flex-col gap-2 mt-2"
          style={{ display: showReferences ? 'flex' : 'none' }}
        >
          {listIds[listId].map((ref, index) => {
            const referenceNumber = index + 1;
            const hasLink = !!ref?.link;
            return (
              <li key={referenceNumber} className="border border-blue-200 rounded p-2">
                <a
                  href={hasLink ? t(ref.link) : null}
                  className="relative block group"
                  target="_blank"
                >
                  <p className="pr-6">
                    ({referenceNumber}) {t(ref.description)}
                  </p>
                  {hasLink && (
                    <div className="group-hover:bg-purple-800 rounded-full h-4 w-4 absolute top-0 right-0 transform transition transition-color flex items-center justify-center">
                      <Icon
                        name="arrow-new"
                        className="text-purple-800 h-3 w-3 transform shrink-0 group-hover:text-white transition transition-color"
                      />
                    </div>
                  )}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </Section>
  );
};

export default Footnote3;
