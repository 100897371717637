import { useCallback, useMemo, useState } from 'react';

import { Alert, AlertProps } from 'components/ui';

export interface UseAlertReturn {
  alert: JSX.Element;
  setAlert: (newAlertProps: AlertProps) => void;
  clearAlert: () => void;
}

/**
 * Use this hook to use an alert in your component
 * Possible use case: Form -> Reacting to API errors, showing success messages
 */
const useAlert = (): UseAlertReturn => {
  const [alertProps, setAlertProps] = useState<AlertProps>();

  const setAlert = useCallback((newAlertProps: AlertProps) => {
    setAlertProps(newAlertProps);
  }, []);

  const clearAlert = () => {
    setAlertProps(null);
  };

  const alert = useMemo(() => alertProps && <Alert {...alertProps} />, [alertProps]);

  return { alert, setAlert, clearAlert };
};

export default useAlert;
