/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';

import { ECImage, Section } from 'components/ui';
import { useCountryContext } from 'context/CountryContextProvider';
import { ImageSetKeys, useImages } from 'context/ImagesProvider';
import { useTranslations } from 'services/translations/TranslationsProvider';

const CompareIngredients = (): JSX.Element => {
  const { img } = useImages(ImageSetKeys.ContentCompareIngredients);
  const { t } = useTranslations('content.compareIngredients');
  const { countryCode } = useCountryContext();

  return (
    <Section color="cyan" className="overflow-hidden" noBottomSpacing>
      <div className="container container--lg relative">
        <div className="mb-8 relative z-10">
          <h3 className="text-2.5xl sm:text-3xl leading-none text-center text-purple-800 font-rodetta max-w-md mx-auto mb-4 text-balance">
            {t('title')}
          </h3>
          <p className="font-rooney text-center text-purple-800 font-medium text-lg mx-auto max-w-copy">
            {t('introduction')}
          </p>
        </div>

        <div className="relative pb-20 pt-48 md:pb-36">
          <div className="flex flex-col md:flex-row gap-3 md:gap-6 justify-center maxMd:items-center">
            {/** Us */}
            <div className="bg-white rounded-sm p-4 md:p-6 flex flex-col w-auto max-w-[350px] md:max-w-[500px] relative shadow z-10 mr-8 xs:mr-12 sm:mr-16 md:mr-0">
              <div className="font-rooney uppercase text-xs font-bold text-[#292929] opacity-50 mb-2 md:mb-3">
                {t('ingredientsLabel')}
              </div>
              <div className="font-rooney text-[#292929] font-normal text-sm md:text-base max-h-[59px] md:max-h-[90px] overflow-hidden">
                <span className="font-rodetta relative z-20 text-purple-800 text-base md:text-lg px-1 box-decoration-clone bg-cyan-400 mr-1">
                  {t('usIngredientListFirst')}
                </span>
                {t('usIngredientListOther')}
              </div>
              <div className="absolute inset-0 top-[20%] bg-gradient-to-t from-[white] overflow-hidden pointer-events-none" />
              <ECImage
                img={img('arrow')}
                className="absolute z-20 w-[80px] bottom-full left-[50%] translate-y-[-20px] translate-x-[-50%] ml-[-30px] 
                      md:translate-x-[0] md:ml-0 md:left-auto md:right-[90px] md:w-[100px] xl:right-[270px]"
                srcOptions={{
                  w: 400,
                  q: 90,
                }}
              />
            </div>

            {/** Them */}
            <div className="bg-white rounded-sm p-4 md:p-6 flex flex-col w-auto max-w-[350px] md:max-w-[500px] relative shadow z-10 ml-8 xs:ml-12 sm:ml-16 md:ml-0">
              <div className="font-rooney uppercase text-xs font-bold text-[#292929] opacity-50 mb-2 md:mb-3">
                {t('ingredientsLabel')}
              </div>
              <div className="font-rooney text-[#292929] font-normal text-sm md:text-base max-h-[59px] md:max-h-[90px] overflow-hidden">
                <span className="font-rodetta relative z-10 text-purple-800 text-base md:text-lg px-1 box-decoration-clone bg-red-300 mr-1">
                  {t('themIngredientListFirst')}
                </span>
                {t('themIngredientListOther')}
              </div>
              <div className="absolute inset-0 top-[20%] bg-gradient-to-t from-[white] overflow-hidden pointer-events-none" />
              <ECImage
                img={img('arrow')}
                className="absolute z-20 w-[80px] -scale-x-100 top-full -scale-y-100 right-[50%] translate-y-[20px] translate-x-[50%] mr-[-30px]
                    md:bottom-full md:w-[100px] md:top-auto md:translate-x-[0] md:translate-y-[-20px] md:scale-y-100 md:right-0 md:mr-0 md:left-[90px] xl:left-[270px]"
                srcOptions={{
                  w: 400,
                  q: 90,
                }}
              />
            </div>
          </div>

          <div className="absolute left-[50%] translate-x-[-50%] ml-[-225px] md:ml-[-350px] xl:ml-[-550px] top-0 w-[550px] md:w-[700px]">
            <ECImage
              img={img('bagEdgardCooper')}
              className="w-full"
              srcOptions={{
                w: 1400,
                q: 90,
              }}
            />
            {countryCode === 'BE' && (
              <ECImage
                img={img('testaankoop')}
                srcOptions={{
                  w: 500,
                  q: 90,
                }}
                className="absolute z-20 w-[20%] top-[6%] left-[68%] md:top-[0%] md:left-[70%]"
              />
            )}
          </div>

          <ECImage
            img={img('bagIndustry')}
            className="absolute right-[50%] translate-x-[50%] mr-[-225px] md:mr-[-350px] xl:mr-[-550px] top-0 max-w-none w-[550px] md:w-[700px]"
            srcOptions={{
              w: 1400,
              q: 90,
            }}
          />
        </div>
      </div>
    </Section>
  );
};

export default CompareIngredients;
