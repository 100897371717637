import { Money, Product, ProductVariant } from 'api/generated-types';
import { gidToVariantId } from 'services/shopify/utils';

// eslint-disable-next-line import/prefer-default-export
export const getProductVariantHref = (productVariant: ProductVariant): string =>
  `/products/${productVariant.product.content.handle}/${gidToVariantId(
    productVariant.identifiers.shopifyProductVariantId,
  )}/`;

export const getProductMinPrice = (product: Product): Money => {
  const minPrice = Math.min(...product.variants.map((variant) => variant.actuals.price.amount));
  return product.variants.find((variant) => variant.actuals.price.amount === minPrice)?.actuals
    .price;
};

export const getProductMaxPrice = (product: Product): Money => {
  const maxPrice = Math.max(...product.variants.map((variant) => variant.actuals.price.amount));
  return product.variants.find((variant) => variant.actuals.price.amount === maxPrice)?.actuals
    .price;
};
