import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { Product } from 'api/generated-types';
import { useCountryContext } from 'context/CountryContextProvider';

export interface UseProductResult {
  isDogKibbleFrenchFrance: boolean;
  isDog: boolean;
  isCat: boolean;
  isChristmasGiftBox: boolean;
  isMeasuringCup: boolean;
  isStorageTin: boolean;
  isFestive: boolean;
  containsProductBundle: boolean;
  containsDifferentRecipeVariant: boolean;
  isTempProduct: boolean;
  isDental: boolean;
  isTreats: boolean;
}

const useProduct = (product: Product): UseProductResult => {
  const { locale } = useRouter();
  const { cookieCountryCode } = useCountryContext();
  const [isDogKibbleFrenchFrance, setIsDogKibbleFrenchFrance] = useState(false);

  useEffect(() => {
    setIsDogKibbleFrenchFrance(
      product.masterData.ranges.length === 1 &&
        product.masterData.ranges[0].key === 'dog' &&
        product.content.universalKey.includes('KIBBLE') &&
        locale === 'fr' &&
        cookieCountryCode === 'FR',
    );
  }, [product, locale, cookieCountryCode]);

  const isDog =
    product.masterData.ranges.length === 1 && product.masterData.ranges[0].key === 'dog';
  const isCat =
    product.masterData.ranges.length === 1 && product.masterData.ranges[0].key === 'cat';
  const isChristmasGiftBox = product.content.handle === 'christmas-gift-box';
  const isMeasuringCup = product.content.handle === 'measuring-cup';
  const isStorageTin = product.content.handle === 'storage-tin';

  const containsDifferentRecipeVariant = product.variants.some((variant) =>
    variant.content.productBundle.some(
      (bundleContent) => bundleContent.productVariant.product.universalKey !== product.universalKey,
    ),
  );

  const containsProductBundle = product.variants.some((variant) => variant.content.isBundle);
  const isDental = product.masterData.categoryGroups.some(
    (categoryGroup) => categoryGroup.key === 'dental',
  );

  const isTreats = product.masterData.categoryGroups.some(
    (categoryGroup) => categoryGroup.key === 'treats',
  );

  const isFestive =
    [
      'DOG_AD_PATE_TURKEY55-FESTIVE',
      'DOG_AD_PATE_TURKEY60-FESTIVE',
      'DOG_JR-AD-SR_BISCUIT_DUCK10-TURKEY16-FESTIVE',
      'BUNDLE_CAT_AD_CHUNKS-FESTIVE-3FLAV-6X85G',
      'CAT_AD_PATE_DUCK10-FESTIVE',
      'CAT_AD_CHUNKS_VENISON7-FESTIVE',
      'BUNDLE_DOG_AD_MIX-FEST-1',
    ].includes(product.content.universalKey) || isChristmasGiftBox;

  const isTempProduct = product.content.universalKey === 'BUNDLE_CAT_AD_PATE-GENERAL-4FLAV-8X85G';

  return {
    isDogKibbleFrenchFrance,
    isDog,
    isCat,
    isChristmasGiftBox,
    isMeasuringCup,
    isStorageTin,
    isFestive,
    containsProductBundle,
    containsDifferentRecipeVariant,
    isTempProduct,
    isDental,
    isTreats,
  };
};

export default useProduct;
