import { useState } from 'react';

import classNames from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Snowfall from 'react-snowfall';
import SwiperType from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Product } from 'api/generated-types';
import Sticker from 'components/parts/Sticker';
import { Button, ECImage, ECLink, Icon } from 'components/ui';
import { useCountryContext } from 'context/CountryContextProvider';
import { useTracking } from 'context/TrackingProvider';
import { useTranslations } from 'services/translations/TranslationsProvider';
import { snowImages } from 'utils/festiveUtils';
import formatPrice from 'utils/formatPrice';
import useProduct from 'utils/hooks/useProduct';
import useViewport from 'utils/hooks/useViewport';
import { getProductMaxPrice, getProductMinPrice } from 'utils/products';

interface Props {
  bestsellers: Product[];
  selectedRange?: 'dogs' | 'cats';
}

interface ProductCardProps {
  product: Product;
}

const productRanges = ['dogs', 'cats'];

const BestsellersProductCard = ({ product }: ProductCardProps): JSX.Element => {
  const { t } = useTranslations('content.bestsellers');
  const { locale } = useRouter();
  const { currencyCode } = useCountryContext();
  const { isFestive } = useProduct(product);

  // This is to avoid adding festival phsyical bundle to the virtual bundle to avoid showing compareAtPrice
  const catFestiveUkey = 'BUNDLE_CAT_AD_CHUNKS-FESTIVE-3FLAV-6X85G';

  const claims = [
    {
      universalKey: 'DOG_AD_KIBBLE_SALMON40',
      claims: [t('claim.fish'), t('claim.grainFree'), t('claim.hypoallergenic')],
    },
    {
      universalKey: 'DOG_AD_PATE_CHICKEN52-TURKEY8',
      claims: [t('claim.meat'), t('claim.grainFree')],
    },
    {
      universalKey: 'DOG_JR_KIBBLE_DUCK10-CHICKEN40',
      claims: [t('claim.freshMeat'), t('claim.grainFree'), t('claim.bonesJoints')],
    },
    {
      universalKey: 'DOG_JR_KIBBLE_SALMON10-TURKEY35',
      claims: [t('claim.meatFish'), t('claim.grainFree'), t('claim.bonesJoints')],
    },
    {
      universalKey: 'DOG_JR_PATE_DUCK10-CHICKEN50',
      claims: [t('claim.meat'), t('claim.grainFree'), t('claim.bonesJoints')],
    },
    {
      universalKey: 'DOG_JR_BITE_DUCK12-CHICKEN33',
      claims: [t('claim.grainFree'), t('claim.lowCalories'), t('claim.omega3')],
    },
    {
      universalKey: 'BUNDLE_DOG_AD_MIX-BEST-1',
      claims: [t('claim.popularRecipes'), t('claim.fullRange'), t('claim.allBreeds')],
    },
    {
      universalKey: 'BUNDLE_DOG_AD_MIX-FEST-1',
      claims: [t('claim.christmasGift'), t('claim.cupsAndBiscuits'), t('claim.noAddedSugar')],
    },
    {
      universalKey: 'BUNDLE_CAT_AD_CHUNKS-FESTIVE-3FLAV-6X85G',
      claims: [t('claim.christmasGift'), t('claim.sterilisedCats'), t('claim.grainFree')],
    },
    {
      universalKey: 'CAT_AD_PATE_DUCK10-FESTIVE',
      claims: [t('claim.christmasGift'), t('claim.sterilisedCats'), t('claim.grainFree')],
    },
    {
      universalKey: 'CAT_AD_KIBBLE_CHICKEN50',
      claims: [t('claim.freshMeat'), t('claim.sterilisedCats'), t('claim.grainFree')],
    },
    {
      universalKey: 'CAT_AD_CHUNKS_CHICKEN46',
      claims: [t('claim.meat'), t('claim.sterilisedCats')],
    },
    {
      universalKey: 'CAT_JR_KIBBLE_DUCK10-CHICKEN40',
      claims: [t('claim.freshMeat'), t('claim.sterilisedCats'), t('claim.grainFree')],
    },
    {
      universalKey: 'CAT_JR_CHUNKS_DUCK7-CHICKEN38',
      claims: [t('claim.meat'), t('claim.sterilisedCats'), t('claim.grainFree')],
    },
    {
      universalKey: 'CAT_JR_PATE_CHICKEN55-COD10',
      claims: [t('claim.meatFish'), t('claim.sterilisedCats'), t('claim.grainFree')],
    },
  ];

  return (
    <ECLink
      href={`/products/${product.content.handle}/`}
      className="flex flex-col h-full text-left cursor-pointer
      transform will-change-transform transition group"
    >
      {/* Product image */}
      <div className="bg-cyan-400 rounded-lg relative mb-4 group-hover:-translate-y-[0.125rem] group-hover:shadow-md transition transform will-change-transform">
        {isFestive && (
          <>
            <Snowfall
              color="#fff"
              snowflakeCount={20}
              radius={[8, 16.0]}
              speed={[0.5, 1]}
              wind={[-0.5, 0.5]}
              style={{ zIndex: 3 }}
              images={snowImages}
            />
            <div className="absolute inset-0 bg-gradient-radial from-[#C4ECEC] to-cyan-400 overflow-hidden rounded-lg z-2 rounded-tr-2xl" />
            <Image
              src="/images/festive/snowcorner.svg"
              alt="snow"
              className="absolute w-[54px] left-0 top-0 -translate-x-[11%] -translate-y-[17%] pointer-events-none"
              width={54}
              height={48}
            />
          </>
        )}
        <div className="absolute top-3 right-3 flex flex-row-reverse flex-gap-x-2 flex-gap-y-1 mb-2 flex-wrap z-10">
          {/* ages sticker */}
          <Sticker
            sticker={{
              displayText:
                product.masterData.ages.length === 1
                  ? product.masterData.ages[0].translations?.find(
                      (translation) => translation.languageCode === locale,
                    )?.translation ?? product.masterData.ages[0].key
                  : t('allAges'),
              backgroundHexColor: '#ecf8f8',
              fontHexColor: '#007377',
              name: 'ageLabel',
              // Not needed, but is required for type
              bannerActiveForCountries: [],
            }}
          />
          {product.content.sticker && (
            <div className="flex justify-center">
              <Sticker sticker={product.content.sticker} />
            </div>
          )}
        </div>
        {/* featured image */}
        <div className="px-4 pb-6 pt-12 relative z-10">
          {product.content.featuredImage && (
            <ECImage
              img={product.content.featuredImage}
              srcOptions={{ w: 720, q: 90 }}
              className="w-full aspect-1 object-contain max-h-[200px] xl:max-h-[250px]"
            />
          )}
        </div>
      </div>
      {/* Product information */}
      <div className="flex flex-col gap-y-1">
        <div
          className={classNames(
            'font-rooney font-bold text-lg md:text-xl leading-tight text-purple-800',
          )}
        >
          {product.content.title}
        </div>
        <div className="font-rooney font-medium text-base text-purple-800">
          {getProductMinPrice(product)?.amount === getProductMaxPrice(product)?.amount ? (
            <>
              {getProductMaxPrice(product)?.formattedPrice}
              {product.variants.some(
                (variant) => variant.content.isBundle && product.universalKey !== catFestiveUkey,
              ) && (
                <span className="line-through text-gray-200 ml-1 font-medium">
                  {formatPrice(getProductMaxPrice(product)?.compareAt, currencyCode, locale)}
                </span>
              )}
            </>
          ) : (
            `${t('from')} ${getProductMinPrice(product).formattedPrice}`
          )}
        </div>

        {/* claims */}
        <ul className="mt-2 flex flex-col gap-y-1">
          {claims
            .filter((productClaims) => productClaims.universalKey === product.universalKey)
            .flatMap((productClaims) => productClaims.claims)
            .map((claim) => (
              <li className="flex items-start gap-x-2" key={claim}>
                <Icon
                  name="checkmark-3"
                  className="text-[#22C076] w-4 h-4 shrink-0 relative top-[2px]"
                />
                <p className="text-purple-800 text-base font-rooney text-left flex-wrap font-medium">
                  {claim}
                </p>
              </li>
            ))}
        </ul>
      </div>
    </ECLink>
  );
};

const BestsellersV2 = ({ bestsellers, selectedRange }: Props): JSX.Element => {
  const hasDogProducts = bestsellers?.filter((p) => p.universalKey.includes('DOG')).length > 0;
  const hasCatProducts = bestsellers?.filter((p) => p.universalKey.includes('CAT')).length > 0;
  const hasMultipleRanges = hasDogProducts && hasCatProducts;

  const [productRange, setProductRange] = useState<'dogs' | 'cats'>(
    selectedRange || (hasDogProducts ? 'dogs' : 'cats'),
  );
  const [swiper, setSwiper] = useState<SwiperType | null>(null);
  const [isBeginning, setIsBeginning] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const { isMobile } = useViewport();

  const ctaUrl = productRange === 'cats' ? '/collections/cats/' : '/collections/dogs/';

  const { t } = useTranslations('content.bestsellers');

  const {
    events: { trackCtaClicked },
  } = useTracking();

  if (!bestsellers) {
    return <></>;
  }

  return (
    <div className="w-full">
      <div className="container">
        <h3
          className={classNames(
            'text-2.5xl sm:text-3xl leading-none text-center text-purple-800 font-rodetta mx-auto mb-4 text-balance',
            !hasMultipleRanges && 'mb-12',
          )}
        >
          {t('bestsellersTitleV2')}
        </h3>
        <p className="font-rooney text-center text-purple-800 font-medium text-lg mx-auto">
          {t('introDescription')}
        </p>
        {/*  Toggle  */}
        {hasMultipleRanges && (
          <div className="mt-8 w-fit mx-auto rounded-full border-2 border-purple-800 flex">
            {productRanges.map((range: 'dogs' | 'cats') => (
              <button
                key={range}
                type="button"
                className={classNames(
                  'relative px-6 py-3 rounded-full w-full min-w-[100px] transition',
                  'font-rodetta text-lg',
                  'first:-mr-1',
                  'last:-ml-1',
                  productRange === range
                    ? 'z-10 bg-purple-800 text-white cursor-default'
                    : 'text-purple-800 hover:text-purple-750',
                )}
                onClick={() => {
                  setProductRange(range);
                  trackCtaClicked({ ctaId: `bestsellers_toggle_${range}` });
                }}
              >
                {t(range)}
              </button>
            ))}
          </div>
        )}
        {/* carousel of product cards */}

        <div className="relative mt-8">
          <Swiper
            onSwiper={(newSwiper) => {
              setSwiper(newSwiper);
              setIsBeginning(newSwiper.isBeginning);
              setIsEnd(newSwiper.isEnd);
            }}
            onRealIndexChange={(s) => {
              setIsBeginning(s.isBeginning);
              setIsEnd(s.isEnd);
            }}
            slidesPerView={1.1}
            spaceBetween={16}
            pagination={{ clickable: true }}
            style={{ paddingTop: '8px' }}
            className="!overflow-visible"
            breakpoints={{
              350: {
                slidesPerView: 1.1,
                spaceBetween: 16,
              },
              640: {
                slidesPerView: 2.1,
                spaceBetween: 16,
              },
              768: {
                slidesPerView: 2.1,
                spaceBetween: 32,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 32,
              },
            }}
          >
            {bestsellers
              ?.filter((product) =>
                product.universalKey.includes(productRange.toUpperCase().replace('S', '')),
              )
              .map((product) => (
                <SwiperSlide
                  style={{
                    height: 'auto',
                    alignSelf: 'stretch',
                    userSelect: 'none',
                  }}
                  key={product.content.universalKey}
                >
                  <BestsellersProductCard product={product} />
                </SwiperSlide>
              ))}
          </Swiper>
          {/* navigation buttons for larger screens */}
          <button
            type="button"
            onClick={() => swiper?.slidePrev(200)}
            className={classNames(
              `maxMd:hidden absolute top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12 bg-purple-800 z-10 rounded-full transition-all
              hover:bg-purple-750 active:bg-purple-750 focus:bg-purple-750`,
              isBeginning ? 'opacity-0' : 'opacity-100',
            )}
            hidden={isMobile}
          >
            <Icon
              name="chevron-left"
              className="w-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-white"
            />
          </button>
          <button
            type="button"
            onClick={() => swiper?.slideNext(200)}
            className={classNames(
              `maxMd:hidden absolute top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2 w-12 h-12 bg-purple-800 z-10 rounded-full transition-all
              hover:bg-purple-750 active:bg-purple-750 focus:bg-purple-750`,
              isEnd ? 'opacity-0' : 'opacity-100',
            )}
            hidden={isMobile}
          >
            <Icon
              name="chevron-right"
              className="w-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-white"
            />
          </button>
        </div>
        <div className="flex justify-center mt-12">
          <ECLink href={ctaUrl} passHref legacyBehavior>
            <Button color="purple-800" size="large">
              {t('ctaLabel')}
            </Button>
          </ECLink>
        </div>
      </div>
    </div>
  );
};

export default BestsellersV2;
