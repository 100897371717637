/* eslint-disable max-len */
import React, { useState } from 'react';

import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import sanitizeHtml from 'sanitize-html-react';

import { Button, ECImage, Icon, TextField } from 'components/ui';
import { useCountryContext } from 'context/CountryContextProvider';
import { ImageSetKeys, useImages } from 'context/ImagesProvider';
import subscribeToKlaviyoList from 'services/klaviyo/subscribeToKlaviyoList';
import { trackRealyticsVisit } from 'services/realytics/track';
import { useTranslations } from 'services/translations/TranslationsProvider';
import { EMAIL_REGEX } from 'utils/constants';
import useAlert from 'utils/hooks/useAlert';
import useStaticTranslate from 'utils/hooks/useStaticTranslate';

interface FormInput {
  email: string;
}

interface Props {
  // New color
  v2?: boolean;
}

const Newsletter2 = ({ v2 }: Props): JSX.Element => {
  /*
  ID of newsletter list
  See docs: https://help.klaviyo.com/hc/en-us/articles/115005078647-How-to-Find-a-List-ID
  */
  const listId = 'T6zMZ7';
  const { locale } = useRouter();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const { t } = useTranslations('content.newsletter');
  const { img } = useImages(ImageSetKeys.ContentNewsletter);
  const { alert, clearAlert, setAlert } = useAlert();
  const { staticTranslate, staticTranslations } = useStaticTranslate();
  const { countryCode } = useCountryContext();
  const isFrance = countryCode === 'FR';
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormInput>();

  const onSubmit = async (input: FormInput) => {
    clearAlert();

    subscribeToKlaviyoList(listId, {
      email: input.email,
      properties: {
        language: locale,
        'Accepts Marketing': 'true',
      },
    })
      .then(() => {
        setAlert({
          message: t('alerts.newsletter:subscribeToNewsletterSuccess'),
          variant: 'success',
        });
        setIsSubmitted(true);
      })
      .catch(() => {
        setAlert({
          message: t('alerts.newsletter:subscribeToNewsletterError'),
          variant: 'error',
        });
      });
    if (isFrance) {
      trackRealyticsVisit('mail_newsletter');
    }
  };

  const link = `<a href="${staticTranslate(
    staticTranslations.termsAndConditions,
    'countryCode',
  )}" target="_blank" class="text-link">${t('form:newsletterNoticeLink')}</a>`;

  return (
    <div className="container">
      <div className="max-w-sm mx-auto pb-12">
        <h3
          className={classNames(
            'text-center font-rodetta leading-none',
            v2
              ? 'text-purple-800 text-2.5xl sm:text-3xl'
              : 'text-purple-700 text-3xl md:text-3.5xl',
          )}
        >
          {t('newsletterTitle')}
        </h3>
        <p className="mt-4 md:mt-6 font-rooney text-gray-400 text-center text-base md:text-lg">
          {t('newsletterDescription')}
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className="text-center mt-6 sm:mx-0">
          {!!alert && <div className="mb-4 font-rooney font-medium">{alert}</div>}
          {!isSubmitted && (
            <>
              <div
                className={classNames(
                  'relative mx-auto flex p-1 bg-white rounded-lg border border-gray-75 w-full gap-3',
                  v2 ? 'focus-within:border-purple-800' : 'focus-within:border-purple-700',
                )}
              >
                <TextField
                  id="email"
                  placeholder={t('newsletterEmailPlaceholder')}
                  label={t('form:email')}
                  defaultValue=""
                  labelHidden
                  type="email"
                  error={errors.email?.message}
                  {...register('email', {
                    required: t<string>('form:required.email'),
                    pattern: {
                      value: EMAIL_REGEX,
                      message: t<string>('form:invalid.email'),
                    },
                  })}
                  className="border-none bg-white !text-purple-700 !pl-4 !pr-1 py-1 h-full w-full font-rooney text-base font-medium leading-5 focus:!border-none focus:!ring-transparent"
                />
                <Button
                  legacy
                  submit
                  loading={isSubmitting}
                  color={v2 ? 'purple-800' : 'purple'}
                  className="absolute top-0 right-0 bottom-0 !px-8 !py-2 !rounded-md"
                >
                  <Icon name="paw" className="w-8 h-8" />
                </Button>
              </div>
              <p
                className="font-rooney mt-4 text-sm text-gray-400 font-medium leading-[1.125rem] underline-offset-[0.188rem]"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(
                    t('form:newsletterNotice', {
                      link,
                      interpolation: { escapeValue: false },
                    }),
                    {
                      allowedTags: ['a'],
                      allowedAttributes: {
                        a: ['href', 'class', 'target'],
                      },
                    },
                  ),
                }}
              />
            </>
          )}
        </form>
      </div>
      <div className="flex items-end mx-auto justify-center">
        <ECImage img={img('newsletterLeft')} className=" max-h-64" />
        <ECImage img={img('newsletterRight')} className=" max-h-64" />
      </div>
    </div>
  );
};

export default Newsletter2;
