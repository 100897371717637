import * as Sentry from '@sentry/nextjs';

/**
 * Subscribes an email to a Klaviyo list with custom properties
 */
const subscribeToKlaviyoList = async (
  listId: string,
  data: {
    email: string; // email address of the subscriber
    properties?: Record<string, string>;
  },
  // Our production environment is the default, as this will trigger production flow e-mails
  useKlaviyoProductionEnvironment = true,
) => {
  const options = {
    method: 'POST',
    headers: { revision: '2024-02-15', 'content-type': 'application/json' },
    body: JSON.stringify({
      data: {
        type: 'subscription',
        attributes: {
          profile: {
            data: {
              type: 'profile',
              attributes: {
                email: data.email,
                ...(data.properties ? { properties: data.properties } : {}),
              },
              meta: {
                patch_properties: {
                  append: data.properties,
                },
              },
            },
          },
        },
        relationships: { list: { data: { type: 'list', id: listId } } },
      },
    }),
  };

  return fetch(
    `https://a.klaviyo.com/client/subscriptions/?company_id=${
      useKlaviyoProductionEnvironment ? 'LKSC83' : 'Qr4GSC'
    }`,
    options,
  ).catch((err) =>
    Sentry.captureException(err, {
      extra: { listId, data },
    }),
  );
};

export default subscribeToKlaviyoList;
