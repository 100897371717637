/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';

import { useRouter } from 'next/router';

import { ECImage, ECLink, Section } from 'components/ui';
import { useCountryContext } from 'context/CountryContextProvider';
import { ImageSetKeys, useImages } from 'context/ImagesProvider';
import { useTracking } from 'context/TrackingProvider';
import { useTranslations } from 'services/translations/TranslationsProvider';
import formatPrice from 'utils/formatPrice';
import { getPagePath } from 'utils/page-paths';

const RealIngredients = (): JSX.Element => {
  const { img } = useImages(ImageSetKeys.ContentRealIngredients);
  const { t } = useTranslations('content.realIngredients');
  const { locale } = useRouter();
  const { currencyCode, countryCode } = useCountryContext();

  let dogPrice = 1.29;
  let catPrice = 0.79;

  if (countryCode === 'GB') {
    dogPrice = 1.09;
    catPrice = 0.69;
  }

  const {
    events: { trackCtaClicked },
  } = useTracking();

  return (
    <Section color="green-350" noBottomSpacing>
      <div className="container">
        <h3 className="text-2.5xl sm:text-3xl leading-none text-center text-purple-800 font-rodetta max-w-sm mx-auto mb-4 text-balance">
          {t('title')}
        </h3>
        <p className="font-rooney text-center text-purple-800 font-medium text-lg mx-auto">
          {t('introduction')}
        </p>

        <div className="flex flex-gap-x-6 mt-12 justify-center">
          <ECLink
            href={getPagePath({ path: '/collections/dogs', locale })}
            className="bg-white rounded p-2 w-[120px] sm:w-[150px] shadow rotate-[-2deg] hover:-translate-y-0.5 hover:shadow-md transition"
            onClick={() => trackCtaClicked({ ctaId: 'real_ingredients_shop_dogs' })}
          >
            <div className="bg-cyan-400 rounded-sm h-[80px] sm:h-[100px] overflow-hidden relative">
              <ECImage
                img={img('frameCooper')}
                className="w-full pointer-events-none"
                srcOptions={{
                  w: 300,
                  q: 90,
                }}
              />
            </div>
            <div className="font-rooney text-purple-800 flex flex-col text-center pt-2 pb-1">
              <span className="text-sm">{t('startingFrom')}</span>
              <span className="text-base font-bold">
                {formatPrice(dogPrice, currencyCode, locale)} / {t('day')}
              </span>
            </div>
          </ECLink>
          <ECLink
            href={getPagePath({ path: '/collections/cats', locale })}
            className="bg-white rounded p-2 w-[120px] sm:w-[150px] shadow rotate-[2deg] hover:-translate-y-0.5 hover:shadow-md transition"
            onClick={() => trackCtaClicked({ ctaId: 'real_ingredients_shop_cats' })}
          >
            <div className="bg-cyan-400 rounded-sm h-[80px] sm:h-[100px] overflow-hidden relative">
              <ECImage
                img={img('frameRoxie')}
                className="w-full pointer-events-none"
                srcOptions={{
                  w: 300,
                  q: 90,
                }}
              />
            </div>
            <div className="font-rooney text-purple-800 flex flex-col text-center pt-2 pb-1">
              <span className="text-sm">{t('startingFrom')}</span>
              <span className="text-base font-bold">
                {formatPrice(catPrice, currencyCode, locale)} / {t('day')}
              </span>
            </div>
          </ECLink>
        </div>
      </div>

      <div className="maxSm:px-0 container mt-8">
        <div className="block sm:w-[150%] sm:-ml-[25%]">
          <ECImage
            img={img('bannerDesktopText')}
            className="maxSm:hidden max-w-none w-full pointer-events-none"
            srcOptions={{
              w: 3500,
              q: 95,
            }}
          />
          <ECImage
            img={img('bannerMobileText')}
            className="sm:hidden w-full pointer-events-none"
            srcOptions={{
              w: 1200,
              q: 95,
            }}
          />
        </div>
      </div>
    </Section>
  );
};

export default RealIngredients;
